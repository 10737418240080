import { styled } from 'styled-components';

import { colors, cssBreakpoints } from 'theme/theme';

export const FieldErrorContainer = styled.div<{
  $inlineLabel?: boolean;
  $noWrap?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-size: 13px;
  color: ${colors.alertRed};
  ${(props) =>
    props.$inlineLabel &&
    `
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    margin-top: 0;
  `}
  ${(props) =>
    props.$noWrap
      ? `
  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    white-space: nowrap;
    max-width: 560px;
  }`
      : ''}
`;
