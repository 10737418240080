import { styled } from 'styled-components';

import { cssBreakpoints, textStyles, transition } from 'theme/theme';

export const FieldLabel = styled.label<{
  $noWrap?: boolean;
  $hasFocus?: boolean;
  $hasValue?: boolean;
  $inline?: boolean;
}>`
  ${textStyles.h5};

  ${(props) =>
    props.$noWrap &&
    `
    @media all and (min-width: ${cssBreakpoints.mdUp}) {
      white-space: nowrap;
      max-width: 560px;
    }`}

  ${(props) => {
    if (props.$inline) {
      return `
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 18px;
        transform: translateY(-50%);
        pointer-events: none;
        transform-origin: left top;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.4px;
        transition: all ${transition};

      ${
        (props.$hasFocus || props.$hasValue) &&
        `
          transform: scale(0.625) translateY(-50%) translateY(-28px);
          opacity: 0.8;
        `
      }`;
    }
  }}
`;
