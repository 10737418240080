const lottieFilePath = '/static/libs/lottie-web@5.11.0.min.js';
let lottiePromise: Promise<void>;

export function loadLottieOnce() {
  // TODO: Fix this from eslint v9 update
  // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
  if (typeof window === 'undefined') return Promise.reject();

  if (!lottiePromise) {
    lottiePromise = new Promise((resolve, reject) => {
      if (document.head.querySelector(`script[src="${lottieFilePath}"]`)) {
        resolve(undefined);
      } else {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.onload = () => resolve();
        script.onerror = reject;
        script.src = lottieFilePath;
        document.head.appendChild(script);
      }
    });
  }

  return lottiePromise;
}
