import type { ReactNode } from 'react';

import { Input } from 'components/Input/Input';
import type { InputAffixVariant } from 'components/Input/Input.types';
import type { InputProps } from 'components/Input/InputProps';

import { CharacterCounter } from './CharacterCounter';
import { Field } from './Field/Field';
import type { FieldCustomErrorMatcher } from './Field/FieldCustomErrorMatcher';

type Props = InputProps & {
  affixVariant?: InputAffixVariant;
  customErrorMatch?: FieldCustomErrorMatcher;
  description?: ReactNode;
  fullHeight?: boolean;
  fullWidth?: boolean;
  inlineLabel?: boolean;
  label?: ReactNode;
  errorRef?: React.RefObject<HTMLDivElement>;
  name: string;
  mapError?: (arg0: string) => ReactNode;
  matchGeneralError?: string;
  noMargin?: boolean;
  noWrap?: boolean;
  onUpdateValue?: (arg0: string) => void;
  prefix?: ReactNode;
  suffix?: ReactNode;
};

export function InputField({
  affixVariant,
  'aria-activedescendant': ariaActiveDescendant,
  'aria-autocomplete': ariaAutocomplete,
  'aria-controls': ariaControls,
  'aria-label': ariaLabel,
  autoComplete,
  autoFocus,
  customErrorMatch,
  dataModalFocus,
  defaultValue,
  description,
  disabled,
  fullHeight,
  fullWidth,
  id,
  inlineLabel,
  label,
  errorRef,
  mapError,
  matchGeneralError,
  maxLength,
  max,
  min,
  name,
  noMargin,
  noWrap,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  onUpdateValue,
  placeholder,
  prefix,
  readOnly,
  required,
  step,
  suffix,
  title,
  type,
}: Props) {
  const inputProps = {
    ariaActiveDescendant,
    ariaAutocomplete,
    ariaControls,
    ariaLabel,
    autoComplete,
    autoFocus,
    dataModalFocus,
    defaultValue,
    disabled,
    id,
    max,
    min,
    name,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyDown,
    onKeyPress,
    onKeyUp,
    onUpdateValue,
    placeholder,
    readOnly,
    required,
    step,
    title,
    type,
  };

  return (
    <Field
      customErrorMatch={customErrorMatch}
      inputProps={inputProps}
      name={name}
      noMargin={noMargin}
      noWrap={noWrap}
      label={label}
      errorRef={errorRef}
      inlineLabel={inlineLabel}
      description={description}
      mapError={mapError}
      matchGeneralError={matchGeneralError}
      renderInput={({ qaId, updateValue, hasError, value }) => (
        <>
          <Input
            inputProps={{
              ...inputProps,
              value: value || '',
              defaultValue,
              qaId: qaId || '',
              onChange:
                onChange ||
                ((e) => {
                  const v = e.target.value;
                  updateValue(v);

                  if (onUpdateValue) {
                    onUpdateValue(v);
                  }
                }),
              'aria-invalid': hasError,
            }}
            affixVariant={affixVariant}
            prefix={prefix}
            suffix={suffix}
            fullWidth={fullWidth}
            fullHeight={fullHeight}
            status={hasError ? 'error' : 'neutral'}
          />
          {maxLength && (
            <CharacterCounter
              maxLength={maxLength}
              currentCount={(value || '').length}
            />
          )}
        </>
      )}
    />
  );
}
