import type { ReactNode } from 'react';

import { Icon } from 'components/Icon/Icon';
import { Text } from 'components/Text/Text';

import { FieldErrorContainer } from './FieldErrorContainer';

type Props = {
  errorMessage: ReactNode;
  inlineLabel?: boolean;
  noWrap?: boolean;
  inputId?: string;
};

export function FieldError({
  errorMessage,
  inlineLabel,
  noWrap,
  inputId,
}: Props) {
  return (
    <FieldErrorContainer $noWrap={noWrap} $inlineLabel={inlineLabel}>
      <Icon name="error" size={24} />
      <Text pl="4px" data-qa-id={inputId ? `${inputId}-error` : undefined}>
        {errorMessage}
      </Text>
    </FieldErrorContainer>
  );
}
