import { styled } from 'styled-components';

export const LottieAnimationContainer = styled.div`
  line-height: 0;
  position: relative;
  overflow: hidden;
`;

export const LottieAnimationRefDiv = styled.div`
  position: relative;
  z-index: 2;
`;

export const LottieAnimationFallbackContainer = styled.div`
  position: absolute;
  left: 0;
  line-height: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const LottieAnimationImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
