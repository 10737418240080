import type { MutableRefObject, ReactNode, RefObject } from 'react';

import { Box } from 'components/Box/Box';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { Portal } from 'components/Portal/Portal';

import { FieldContainer } from './FieldContainer';
import { FieldDescription } from './FieldDescription';
import { FieldError } from './FieldError';
import { FieldLabel } from './FieldLabel';

type Props = {
  label?: ReactNode;
  children: ReactNode;
  description?: ReactNode;
  inputId?: string;
  labelRef?: MutableRefObject<HTMLLabelElement | null>;
  errorRef?: RefObject<HTMLDivElement | null>;
  className?: string;
  inlineLabel?: boolean;
  hidden?: boolean;
  noMargin?: boolean;
  noWrap?: boolean;
  error?: ReactNode;
  hasFocus?: boolean;
  hasValue?: boolean;
};

export function FieldGroup({
  label,
  children,
  description,
  inputId,
  labelRef,
  errorRef,
  className,
  inlineLabel,
  hidden,
  noMargin,
  noWrap,
  error,
  hasFocus,
  hasValue,
}: Props) {
  if (hidden) {
    return (
      <Box position="relative" width="100%">
        {children}

        {error && (
          <InfoBox className="error" $type="danger" $marginBottom={4}>
            {error}
          </InfoBox>
        )}
      </Box>
    );
  }

  const hasMarginBottom = Boolean(!inlineLabel && !noMargin);

  return (
    <FieldContainer
      className={error ? `${className} error` : className} // used to scroll to error
      style={{
        position: 'relative',
        width: '100%',
        marginBottom: hasMarginBottom ? undefined : 0,
      }}
    >
      {label && (
        <FieldLabel
          htmlFor={inputId}
          ref={labelRef}
          id={inputId ? `${inputId}-label` : undefined}
          $hasFocus={hasFocus}
          $hasValue={hasValue}
          $noWrap={noWrap}
          $inline={inlineLabel}
        >
          {label}
        </FieldLabel>
      )}

      {description && (
        <FieldDescription $noWrap={noWrap}>
          <div>{description}</div>
        </FieldDescription>
      )}

      <div>{children}</div>

      {error && errorRef?.current && (
        <Portal container={errorRef.current}>
          <FieldError
            inlineLabel={inlineLabel}
            errorMessage={error}
            inputId={inputId}
            noWrap={noWrap}
          />
        </Portal>
      )}
      {error && !errorRef?.current && (
        <FieldError
          inlineLabel={inlineLabel}
          errorMessage={error}
          inputId={inputId}
          noWrap={noWrap}
        />
      )}
    </FieldContainer>
  );
}
