import type { AnimationItem, LottiePlayer } from 'lottie-web';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useLatest } from 'hooks/useLatest';

import type { LottieAnimationOptions } from './LottieAnimationOptions';
import { useLottiePlayer } from './useLottiePlayer';

type Args = {
  src: string;
  options?: LottieAnimationOptions;
};

/**
 * Lazy load the lottie library and the JSON animation to avoid increasing the
 * first paint bundle.
 */
export function useLottieAnimation({ src, options = {} }: Args) {
  const animationHolderRef = useRef<HTMLDivElement>(null);

  const [lottieAnimation, setLottieAnimation] = useState<AnimationItem>();
  const [animationReady, setAnimationReady] = useState(false);
  const loopCountRef = useRef(1);

  const { lottiePlayer } = useLottiePlayer();

  const animationPlayerRef = useRef<LottiePlayer>();
  const playAnimation = useCallback(() => {
    animationPlayerRef.current?.play();
  }, []);

  const reverseRef = useLatest(options.reverse);

  useEffect(() => {
    if (animationReady && lottieAnimation) {
      if (options.reverse) {
        lottieAnimation.setDirection(-1);
      } else {
        lottieAnimation.setDirection(1);
      }
      if (!options.noAutoplay) {
        setTimeout(() => lottieAnimation.play(), 1);
      }
    }
  }, [
    animationReady,
    lottieAnimation,
    options.reverse,
    options.noAutoplay,
    playAnimation,
  ]);

  useEffect(() => {
    const animationHolder = animationHolderRef.current;
    if (!lottiePlayer || !animationHolder) return;

    const animation = lottiePlayer.loadAnimation({
      container: animationHolder,
      renderer: 'svg',
      loop: !options?.maxLoopCount || options.maxLoopCount !== 1,
      path: src,
      autoplay: !options?.noAutoplay,
    });

    animation.addEventListener('data_ready', () => {
      setAnimationReady(true);

      // @NOTE - adding `options.reverse` to the deps breaks the animation, use it as a ref instead
      if (!reverseRef.current) {
        animation.goToAndStop(animation.totalFrames, true);
      }

      if (!options.noAutoplay) setTimeout(() => animation.play(), 1);
    });

    animation.addEventListener('loopComplete', () => {
      if (!options.maxLoopCount) return;

      loopCountRef.current += 1;

      const loopCount = loopCountRef.current;
      if (loopCount >= options.maxLoopCount) animation.setLoop(false);
    });

    setLottieAnimation(animation);

    return () => animation.destroy();
  }, [lottiePlayer, options.maxLoopCount, options.noAutoplay, reverseRef, src]);

  return {
    lottieAnimation,
    animationHolderRef,
    animationReady,
    playAnimation,
  };
}
