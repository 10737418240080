import type { MouseEvent, ReactElement } from 'react';

import { cssFilterForColor } from 'theme/cssFilterForColor';
import { getStaticImageSrc } from 'utils/getImageSrc';

import {
  LottieAnimationContainer,
  LottieAnimationFallbackContainer,
  LottieAnimationImg,
  LottieAnimationRefDiv,
} from './LottieAnimation.styled';
import type { LottieAnimationData } from './LottieAnimationData';
import type { LottieAnimationOptions } from './LottieAnimationOptions';
import { useLottieAnimation } from './useLottieAnimation';

type Props<TPath extends string, TFallbackPath extends string | undefined> = {
  data: LottieAnimationData<TPath, TFallbackPath>;
  options?: LottieAnimationOptions;
  onClick?: (e?: MouseEvent) => void;

  /**
   * Either provide a size, or make sure the parent container does to avoid layout shift.
   *
   * If the parent container already has a size, use `size={undefined}`.
   * This prop is intentionally not optional to avoid being missed.
   */
  size:
    | {
        width: number | undefined;
        height: number | undefined;
        expand?: boolean;
      }
    | undefined;
};

export function LottieAnimation<
  TPath extends string,
  TFallbackPath extends string | undefined,
>({ data, options, onClick, size }: Props<TPath, TFallbackPath>): ReactElement {
  const src = getStaticImageSrc(data.path);
  const fallbackSrc = data.fallbackPath
    ? getStaticImageSrc(data.fallbackPath)
    : undefined;

  const { animationHolderRef, lottieAnimation, animationReady } =
    useLottieAnimation({ src, options });

  const width = size ? size.width : '100%';
  const height = size ? size.height : '100%';

  const aspectRatio =
    size?.width && size?.height ? undefined : `${data.width} / ${data.height}`;

  return (
    <LottieAnimationContainer
      style={{ width, height, aspectRatio }}
      onClick={onClick}
      onMouseEnter={() =>
        options?.animateOnHover && animationReady
          ? lottieAnimation?.play()
          : undefined
      }
      onMouseLeave={() =>
        options?.animateOnHover && animationReady
          ? lottieAnimation?.stop()
          : undefined
      }
    >
      <LottieAnimationRefDiv ref={animationHolderRef} />

      {fallbackSrc && !animationReady && (
        <LottieAnimationFallbackContainer>
          <LottieAnimationImg
            src={fallbackSrc}
            alt=""
            width={data.width}
            height={data.height}
            style={{
              filter: data.color ? cssFilterForColor(data.color) : undefined,
              width,
              height,
            }}
          />
        </LottieAnimationFallbackContainer>
      )}
    </LottieAnimationContainer>
  );
}
