import type { Color } from 'theme/theme';

// You can use this Codepen https://codepen.io/sosuke/pen/Pjoqqp
// to convert HEX colors to filter
const filters: Record<Color, string> = {
  black: 'unset',
  white: 'invert(100%)',
  brandBlue:
    'invert(29%) sepia(100%) saturate(1392%) hue-rotate(193deg) brightness(94%) contrast(94%)',
  brandGreen:
    'invert(61%) sepia(56%) saturate(425%) hue-rotate(62deg) brightness(95%) contrast(91%)',
  brandYellow:
    'invert(80%) sepia(63%) saturate(1482%) hue-rotate(358deg) brightness(97%) contrast(100%)',
  skyBlue:
    'invert(89%) sepia(24%) saturate(6406%) hue-rotate(169deg) brightness(93%) contrast(103%)',
  secondarySkyBlue:
    'invert(49%) sepia(64%) saturate(3020%) hue-rotate(183deg) brightness(95%) contrast(92%)',
  softBlue:
    'invert(83%) sepia(17%) saturate(416%) hue-rotate(192deg) brightness(111%) contrast(102%)',
  backgroundBlue:
    'invert(100%) sepia(24%) saturate(1909%) hue-rotate(177deg) brightness(99%) contrast(107%)',
  selectionBlue:
    'invert(100%) sepia(94%) saturate(1486%) hue-rotate(173deg) brightness(100%) contrast(107%)',
  dustyBlue:
    'invert(85%) sepia(12%) saturate(305%) hue-rotate(168deg) brightness(92%) contrast(98%)',
  lightYellow:
    'invert(91%) sepia(5%) saturate(4672%) hue-rotate(310deg) brightness(125%) contrast(101%)',
  lightRed:
    'invert(75%) sepia(4%) saturate(2742%) hue-rotate(313deg) brightness(117%) contrast(100%)',
  lightSage:
    'invert(92%) sepia(15%) saturate(557%) hue-rotate(56deg) brightness(107%) contrast(98%)',
  lightPurple:
    'invert(89%) sepia(6%) saturate(2898%) hue-rotate(195deg) brightness(111%) contrast(101%)',
  backgroundGrey:
    'invert(87%) sepia(38%) saturate(24%) hue-rotate(123deg) brightness(114%) contrast(97%)',
  inputGrey:
    'invert(100%) sepia(1%) saturate(5051%) hue-rotate(141deg) brightness(119%) contrast(93%)',
  calloutGrey:
    'invert(100%) sepia(17%) saturate(244%) hue-rotate(227deg) brightness(113%) contrast(89%)',
  selectionGrey:
    'invert(99%) sepia(0%) saturate(7210%) hue-rotate(175deg) brightness(114%) contrast(80%)',
  elementGrey:
    'invert(87%) sepia(7%) saturate(147%) hue-rotate(169deg) brightness(95%) contrast(82%)',
  lightContentGrey:
    'invert(45%) sepia(7%) saturate(142%) hue-rotate(169deg) brightness(96%) contrast(80%)',
  mediumContentGrey:
    'invert(24%) sepia(0%) saturate(1%) hue-rotate(267deg) brightness(102%) contrast(85%)',
  darkContentGrey:
    'invert(13%) sepia(36%) saturate(0%) hue-rotate(243deg) brightness(88%) contrast(95%)',
  hoverBlue:
    'invert(20%) sepia(52%) saturate(3751%) hue-rotate(208deg) brightness(93%) contrast(122%)',
  decorativePlum:
    'invert(36%) sepia(9%) saturate(2681%) hue-rotate(213deg) brightness(95%) contrast(87%)',
  decorativeStraw:
    'invert(81%) sepia(80%) saturate(344%) hue-rotate(337deg) brightness(100%) contrast(96%)',
  brightPlum:
    'invert(18%) sepia(88%) saturate(4821%) hue-rotate(275deg) brightness(92%) contrast(126%)',
  alertRed:
    'invert(18%) sepia(47%) saturate(5574%) hue-rotate(341deg) brightness(83%) contrast(119%)',
  alertRedHover:
    'invert(21%) sepia(60%) saturate(7439%) hue-rotate(343deg) brightness(67%) contrast(118%)',
  natureGreen:
    'invert(35%) sepia(12%) saturate(6105%) hue-rotate(74deg) brightness(96%) contrast(80%)',
  tagLemonGreen:
    'invert(76%) sepia(99%) saturate(290%) hue-rotate(49deg) brightness(92%) contrast(99%)',
  lemon:
    'invert(85%) sepia(50%) saturate(680%) hue-rotate(8deg) brightness(109%) contrast(98%)',
  decorativePoppy:
    'invert(32%) sepia(74%) saturate(1828%) hue-rotate(333deg) brightness(98%) contrast(92%)',
  accessibleYellow:
    'invert(47%) sepia(89%) saturate(502%) hue-rotate(2deg) brightness(100%) contrast(98%)',
};

export function cssFilterForColor(color: Color): string | undefined {
  return filters[color];
}
